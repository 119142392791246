import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from '../../../routes/paths';
import UserCheck from '../../../assets/icons/userCheck';
import ConfirmDialog from '../../../components/confirm-dialog';
import { dispatch } from '../../../redux/store';
import { resetStep } from '../../../redux/slices/prothese/protheseSlice';
import { Can } from '../../../auth/can';
export default function StepperPopup({ openConfirm = true, setOpenConfirm, handleNext, step, id, text, }) {
    const navigate = useNavigate();
    return (_jsx(_Fragment, { children: _jsx(ConfirmDialog, { cancelText: `Annuler`, open: openConfirm, onClose: () => navigate('/dashboard/patient'), title: "carte client a \u00E9t\u00E9 cr\u00E9\u00E9e", content: _jsxs(_Fragment, { children: [_jsx(UserCheck, { sx: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '1rem',
                        } }), _jsx(Typography, { sx: {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '1rem',
                            textAlign: 'center',
                            color: 'text.secondary',
                        }, children: text || `souhaitez-vous passer à l'étape suivante de la création d'une prothèse` })] }), action: _jsx(_Fragment, { children: _jsx(Can, { I: "create", a: "Command", children: _jsx(Button, { variant: "contained", onClick: () => {
                            dispatch(resetStep());
                            navigate(PATH_DASHBOARD.patientForm
                                .replace(':step', (step + 1).toString())
                                .replace(':id?', id)
                                .replace(':treatmentId?', ''));
                            handleNext();
                            setOpenConfirm(false);
                        }, children: "Continuer" }) }) }) }) }));
}
